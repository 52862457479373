import React, { useState} from 'react';
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
import icon1 from '../../assets/images/faqIcon1.png';
import icon2 from '../../assets/images/faqIcon2.png';
import icon3 from '../../assets/images/faqIcon3.png';
import icon4 from '../../assets/images/faqIcon4.png';

import {VideosData, HeaderData, HeaderDataEn, LeafData, SoftwareData, SurveysData} from "./data";
import Videos from './videos';
import Faq from "./faq";
import './index.scss';

const Index = () => {
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const [menuItem, setMenuItem] = useState(0);

  return (
    <div className={`educationPage`}>

      <div className="header">
        <div
          className="title"
          dangerouslySetInnerHTML={{__html: menuItem === 0 ?
              currentLang === 'is' ? HeaderData[0] : HeaderDataEn[0]
              :
              currentLang === 'is' ? HeaderData[1] : HeaderDataEn[1]
          }}
        />
        <div className="headerBoxes">
          <div className={`box ${menuItem === 0 && 'active'}`} onClick={() => setMenuItem(0)}>
            <div className="icon icon1" style={{backgroundImage: "url(" + icon1 + ")"}}/>
            <div className="copy">{currentLang === 'is' ? 'Kennslumyndbönd' : 'Instructional videos'}</div>
          </div>
          <div className={`box ${menuItem === 1 && 'active'}`} onClick={() => setMenuItem(1)}>
            <div className="icon" style={{backgroundImage: "url(" + icon2 + ")"}}/>
            <div className="copy">{currentLang === 'is' ? 'Um Laufið' : 'About the Laufið'}</div>
          </div>
          <div className={`box ${menuItem === 2 && 'active'}`} onClick={() => setMenuItem(2)}>
            <div className="icon" style={{backgroundImage: "url(" + icon3 + ")"}}/>
            <div className="copy">{currentLang === 'is' ? 'Hugbúnaðurinn' : 'The software'}</div>
          </div>
          <div className={`box ${menuItem === 3 && 'active'}`} onClick={() => setMenuItem(3)}>
            <div className="icon" style={{backgroundImage: "url(" + icon4 + ")"}}/>
            <div className="copy">{currentLang === 'is' ? 'Sjálfbærnipælingar' : 'Sustainability surveys'}</div>
          </div>
        </div>
      </div>

      {menuItem === 0 && <Videos data={VideosData} />}
      {menuItem === 1 && <Faq data={LeafData} />}
      {menuItem === 2 && <Faq data={SoftwareData} />}
      {menuItem === 3 && <Faq data={SurveysData} />}

    </div>
  )
};

export default Index