import React, {useState} from 'react';
import {Collapse} from "reactstrap"
import i18n from "../../i18n";
import {HeaderData, HeaderDataEn} from "./data";

const Faq = ({data}) => {
  const currentLang = i18n.language || 'is';
  const [collapse, setCollapse] = useState(999);

  const changeCollapse = (item) => {
    setCollapse(collapse === item ? 999 : item);
  };

  return (
    <div className={`faqPage`}>
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
          <div className="title">{currentLang === 'is' ? 'Um Laufið' : 'About the Laufið'}</div>
          <div className="subTitle"
               dangerouslySetInnerHTML={{__html: currentLang === 'is' ?
                 `Hér höfum við leitast við að svara algengum spurningum sem við höfum <br/> 
                  fengið um Laufið. Ef þið hafið enn spurningar má endilega hafa <br/>
                  samband í kassanum að neðan og við lofum svari.`
               :
                 `Here we have tried to answer common questions we have received <br/>
                 about the Leaf. If you still have questions, please contact us <br/>
                  in the box below and we promise an answer.`
               }}
          />
          <div className="accordion">
            {data.map((item, index) =>
              <div className="item" key={'accordionItem' + index}>
                <button
                  className={`accordion-button ${collapse === index && 'collapsed'}`}
                  type="button"
                  onClick={() => {changeCollapse(index)}}
                >
                  {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
                </button>
                <Collapse className="accordion-collapse" isOpen={collapse === index}>
                  <div className="row">
                    <div className="col col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-8">
                      <div className="accordion-body" dangerouslySetInnerHTML={{
                        __html: item["description_" + currentLang] ? item["description_" + currentLang] : item.description
                      }}/>
                    </div>
                  </div>
                </Collapse>
              </div>
              )}
          </div>
        </div>
      </div>
    </div>
)
};

export default Faq