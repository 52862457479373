import React from 'react';
import ReactPlayer from "react-player";
import i18n from "../../i18n";

const Videos = ({data}) => {
  const currentLang = i18n.language || 'is';

  return (
    <div className={`videosContent`}>
      {data.map((video, index) =>
        <div className="row" key={'videoItem'+index}>
          <div className="col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <ReactPlayer
              className="videoFrame"
              url={video.url}
              playing={false}
              controls
            />
          </div>
          <div className="col col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 desc">
            <div className="title">{video["title_" + currentLang] ? video["title_" + currentLang] : video.title}</div>
            <div className="copy">{video["copy_" + currentLang] ? video["copy_" + currentLang] : video.copy}</div>
          </div>
        </div>
      )}
    </div>
  )
};

export default Videos