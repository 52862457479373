import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {AddCircle, Check} from "@mui/icons-material";
import {Input} from "reactstrap";
import Swal from "sweetalert";

import {createUpdateCompanyUser, deleteCompanyUser, getCompanyUsers} from "../../store/actions/companySetting";
import {LoaderSpinner} from "../../components/Common/Loader";
import AddUser from "./components/addUser";


const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const {loadingUsers, users} = useSelector( state => state.companySetting);

  const [state, setState] = useState({"id": 0});
  const [errorStatus, setErrorStatus] = useState(false);
  const [alert, setAlert] = useState(null);


  useEffect(() => {
    users.length === 0 && dispatch(getCompanyUsers(companyId));
  }, []);


  // plans.length === 0 && dispatch(getRegistrationsPlan(companyId));
  // useEffect(() => {
  //   if(plans && plans.results) {
  //     let plan = plans.results.find(f => f.id === firstCompany.plan_id);
  //     setCurrentPlan(plan);
  //   }
  // }, [plans]);

  if (loadingUsers) return (<LoaderSpinner/>);

  const editUser = (data) => {
    setState({
      id: data.id,
      name: data.name,
      nameValid: true,
      email: data.email,
      emailValid: true,
      phone: data.phone,
      phoneValid: true,
      email_subscribed: data.email_subscribed
    })
  };

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const changeNotif = () => {
    setState({...state, "email_subscribed": !state.email_subscribed});
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  const saveUser = () => {
    let data = {...state};
    data['nameValid'] = state.name.length > 3;
    data['emailValid'] = verifyEmail(state.email);
    data['phoneValid'] = (/^\d{7}$/.test(state.phone));
    data['company_id'] = companyId;

    if(data.nameValid && data.emailValid && data.phoneValid) {
      setState({id: 0});
      dispatch(createUpdateCompanyUser(true, data));
    } else {
      setState(data);
      setErrorStatus(true);
    }
  };

  const deleteUser = () => {
    Swal({
      title: 'Ertu viss um að þú viljir eyða notenda?',
      text: state.name,
      dangerMode: true,
      className: "deleteUserModal",
      buttons: {confirm: t('delete'), cancel: t('cancel')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(deleteCompanyUser(state.id, companyId))
      } else {
        Swal.close();
      }
    });
  };

  const addNewUser = () => {
    // if((currentPlan && currentPlan.id === 3) || (users && users.results && currentPlan && users.results.length < currentPlan.users)) {
      setAlert(<AddUser onCancel={() => setAlert(null)}/>);
    // }
  };

  return (
    <div className="row users">
      <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
        {alert}
        <div className="card">
          <div className="card-title">
            <div className="title">{t('settings_page.user_information')}</div>
            <div
              className={`addNew hover-effect`}
              onClick={() => addNewUser()}
            >
              {t('settings_page.add_user')} <AddCircle/>
            </div>
          </div>
          <div className="card-body">
            <div className="table">
              <div className="head">
                <div className="col first">{t('settings_page.name_of_user')}</div>
                <div className="col">{t('settings_page.email')}</div>
                <div className="col">{t('settings_page.phone_number')}</div>
                <div className="col">{t('settings_page.email_notifications')}</div>
                <div className="col"> </div>
              </div>
              <div className="tableBody">
                {users && users.results.filter(f => f.id !== 62 && f.id !== 32 && f.id !== 5 && f.id !== 3 && f.id !== 64).map((user, userIndex) => {
                  if (user.id === state.id) {
                    return (
                      <div className="row" key={"companyTableRow_"+userIndex}>
                        <div className="col name">
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            value={state.name}
                            className="form-control"
                            onChange={event => change(event, 'name')}
                            invalid={errorStatus && !state.nameValid}
                          />
                        </div>
                        <div className="col email">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            value={state.email}
                            className="form-control"
                            onChange={event => change(event, 'email')}
                            invalid={errorStatus && !state.emailValid}
                          />
                        </div>
                        <div className="col email">
                          <Input
                            id="phone"
                            name="phone"
                            type="text"
                            className="form-control"
                            value={state.phone}
                            onChange={event => change(event, 'phone')}
                            invalid={errorStatus && !state.phoneValid}
                          />
                        </div>
                        <div className="col notification">
                          <Input
                            id="notif"
                            name="notif"
                            type="checkbox"
                            className="form-control"
                            checked={state.email_subscribed}
                            onChange={event => changeNotif()}
                          />
                        </div>
                        <div className="col actions">
                          <div className="action edit hover-effect" onClick={() => deleteUser()}>{t('delete')}</div>
                          <div className="action edit hover-effect" onClick={() => saveUser()}>{t('save')}</div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className="row" key={"companyTableRow_"+userIndex}>
                        <div className="col name">{user.name}</div>
                        <div className="col email">{user.email}</div>
                        <div className="col email">{user.phone}</div>
                        <div className="col email">
                          {user.email_subscribed && <div className="notification"><Check/></div>}
                        </div>
                        <div className="col actions">
                          <div className="action edit hover-effect" onClick={() => editUser(user)}>{t('edit')}</div>
                        </div>
                      </div>
                    )
                  }

                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Users;