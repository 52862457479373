export const HeaderData = [
  `Hagnýtur fróðleikur um <br/> Laufið og sjálfbærnimál`,
  `Algengar spurningar og <br/> kennslumyndbönd um Laufið`
];
export const HeaderDataEn = [
  `Practical information about <br/> the Leaf and sustainability issues`,
  `Frequently asked questions and <br/> tutorial videos about the Leaf`
];

export const VideosData = [
  {
    'url': 'https://youtu.be/VqgWHA3SPbk',
    'title': 'Hvar er best að byrja?',
    'title_en': 'Where is the best place to start?',
    'copy': 'Það getur verið erfitt að vita hvar er best að byrja! Við mælum með að horfa á þetta stutta myndband þar sem Sveinborg vísar veginn.',
    'copy_en': 'It can be hard to know where to start! We recommend watching this short video where Sveinborg shows the way.'
  },
  {
    'url': 'https://youtu.be/ujrUVWuZ02A',
    'title': 'Hvernig set ég aðgerðir í áætlun?',
    'title_en': 'How do I schedule actions?',
    'copy': 'Mikilvægt er að setja sér markmið. Allar aðgerðir okkar byggja á að notendur geti sett sér raunhæf markmið og tengt aðgerðirnar við markmiðin.',
    'copy_en': 'It is important to set goals. All our actions are based on users being able to set realistic goals and connect the actions to the goals.'
  },
  {
    'url': 'https://youtu.be/NLbRkwQWePA',
    'title': 'Hvernig reikna ég kolefnisfótspor?',
    'title_en': 'How do I calculate my carbon footprint?',
    'copy': 'Það hefur aldrei verið einfaldara að reikna kolefnisspor! Sveinborg fer yfir reikninn og sýnir að með smá athugun í bókhaldið er einfalt mál að byrja að reikna.',
    'copy_en': 'Calculating your carbon footprint has never been easier! Sveinborg goes over the calculation and shows that with a little observation in the accounting it is a simple matter to start calculating.'
  }
];

export const LeafData = [
  {
    'title': 'Hver er munurinn á Laufinu og öðrum sjálfbærnilausnum á markaðnum í dag?',
    'title_en': 'What sets Laufið apart from other sustainability software solutions on the market today?',
    'description': 'Laufið er einstakt þar sem það býður upp á hagkvæman, aðgengilegan og einfaldan hugbúnað sem setur sjálfbærnimálin fram á mannamáli. Hugbúnaðurinn heldur utan um sjálfbærnivegferðina frá A til Ö, en með notkun á honum verða til verðmæt gögn sem hægt er að nýta áfram. Lausnir sem hugbúnaðurinn býður þá upp á eru aðgerðamiðuð og mælanleg skref byggð á alþjóðlega viðurkenndum viðmiðum, einföld kolefnisreiknivél, aðgerðaáætlun og markmiðasetning og mælaborð sem gefur heildstætt yfirlit yfir sjálfbærnivegferð fyrirtækja og stofnana. <br/><br/> Hægt er að lesa meira um lausnir í hugbúnaði Laufsins <a href="https://www.laufid.is/lausnir/fyrirtaeki/" target="_blank" rel="noopener noreferrer">hér.</a>',
    'description_en': 'Laufið provides affordable, accessible, and user-friendly software that explains sustainability in a clear manner. The software keeps track of the sustainability journey from A to Z, but using it creates valuable data that can be used to present to stakeholders. The solutions provided in the software are action-oriented and offer measurable steps based on internationally accepted criteria. Additionally, it includes a simple carbon calculator, an action plan and goal setting, and a dashboard that provides a comprehensive overview of the sustainability journey for companies and public and private institutions. More information about the software can be found here.'
  },
  {
    'title': 'Hvernig er hægt að hafa samband við ykkur?',
    'title_en': 'I need to reach out, how can I contact you?',
    'description': 'Hægt er að hafa samband við okkur í tölvupósti með netfanginu laufid@laufid.is eða með því að senda okkur skilaboð hér og þér verður svarað um hæl.',
    'description_en': 'You can reach us by sending an email to laufid@laufid.is or by sending us a message here. We will get back to you as soon as possible.'
  },
  {
    'title': 'Afhverju ætti fyrirtækið að kaupa áskrift að hugbúnaði Laufsins?',
    'title_en': 'Why should my company subscribe to Laufið’s software?',
    'description': 'Af því að hugbúnaðurinn veitir fyrirtækinu aðgerðamiðaðar og skýrar lausnir, og hugmyndir sem skilar sér margfalt til baka til lengri tíma litið, bæði hvað varðar árangur í sjálfbærnimálum og fjárhagslega.',
    'description_en': 'Because the software provides your company with actionable and clear solutions and ideas, and it will pay off many times over in the long run, both in terms of success on your sustainability journey and financially.'
  },
  {
    'title': 'Fyrirtækið er að reikna kolefnissporið með öðru fyrirtæki, þurfum við að hætta því til þess að vera í Laufinu?',
    'title_en': 'Our company calculates its carbon footprint with another company, do we have to stop doing that to subscribe to Laufið?',
    'description': 'Nei, síður en svo! Hægt er að setja inn kolefnislosun sem reiknuð er af þriðja aðila inn í hugbúnað Laufsins með einföldum hætti. Þannig er hægt að hafa yfirsýn yfir losunina með þægilegum hætti, ásamt því að hafa góða yfirsýn yfir aðra þætti og gögn sem verða til við notkun á hugbúnaðinum. Á meðan meðvitund um losun fyrirtækisins er afar mikilvæg í baráttunni við loftslagsvána, þá er það eingögnu partur af sjálfbærnivegferðinni.',
    'description_en': 'No, that\'s not the case at all. Carbon emissions calculated by a third party can easily be input into Laufið’s software. This allows for a convenient overview of the emissions, as well as other important data generated by using the software. While it\'s crucial to be aware of your company’s emissions in the fight against climate change, it\'s just one part of the entire sustainability journey.'
  },
  {
    'title': 'Fyrirtækið er nú þegar í áskrift en við værum til í að kaupa sjálfbærniráðgjöf aukalega, er það hægt?',
    'title_en': 'The company already subscribes to Laufið but we would like to buy additional sustainability consultation, is that possible?',
    'description': 'Það er lítið mál að kaupa sjálfbærniráðgjöf aukalega ef það er ekki innifalið í ykkar áskriftarpakka. Endilega hafið samband við starfsfólk Laufsins til þess að fá tilboð í sjálfbærniráðgjöf. Hægt er að senda fyrirspurnina á laufid@laufid.is.',
    'description_en': 'Of course, you can buy additional sustainability consultation if it’s not included in your current subscription package. Please contact us to receive a quote for additional sustainability consultation. You can send the inquiry to laufid@laufid.is.'
  },
  {
    'title': 'Á hverju er aðgerðirnar byggðar?',
    'title_en': 'What are the actions based on?',
    'description': 'Aðgerðir Laufsins eru byggðar á UFS viðmiðum Nasdaq og grænum skrefum í ríkisrekstri sem hafa verið aðlagaðar að atvinnulífinu. Þá hafa aðgerðirnar einnig verið miðaðar við önnur viðmið, staðla og reglugerður á borð við GRI, heimsmarkmið Sameinuðu þjóðanna, UNGC og SASB. Laufið leggur þá ríka áherslu á að fylgjast með þróun sjálfbærnireglugerða og að byggja lausnir sem auðvelda fyrirtækjum að framfylgja þeim.',
    'description_en': 'Laufið’s actions are based on Nasdaq’s ESG criteria as well as green steps in government operations (Græn skref í ríkisrekstri) that have been adapted to businesses. The actions have also been based on other criteria, standards and regulations such as GRI, the United Nations Global Goals, UNGC and SASB. Laufið places great emphasis on monitoring the development of sustainability regulations and building solutions that make it easier for companies to enforce them.'
  },
  {
    'title': 'Í hverju felst þjónustan?',
    'title_en': 'What does the service consist of?',
    'description': 'Þjónustan felur í sér aðgengi að Laufinu, sýnileika á laufid.is og hýsingu gagna. Það fer svo eftir áskriftarleið hvaða meiri þjónusta er innifalin. Hægt er að lesa nánar um þjónustuna <a href="https://www.laufid.is/lausnir/fyrirtaeki/" target="_blank" rel="noopener noreferrer">hér</a> og áskriftarleiðir <a href="https://www.laufid.is/verdskra" target="_blank" rel="noopener noreferrer">hér.</a>',
    'description_en': 'The service consists of access to Laufið, visibility on laufid.is and data hosting. It then depends on the subscription package what extra service is included. You can read more about the service here and the subscription packages here.'
  },
  {
    'title': 'Er Laufið vottun?',
    'title_en': 'Is Laufið a certification?',
    'description': 'Nei, Laufið er ekki vottun, heldur sjálfbærnihugbúnaður sem aðstoðar fyrirtæki og stofnanir við að feta sjálfbærnivegferðina, afla gagna á sviði sjálfbærrar þróunar, og halda utan um þau. Fyrirtæki geta því nýtt sér Laufið við að öðlast sjálfbærnitengdar vottanir.',
    'description_en': 'No, Laufið is not a certification, but a sustainability software that helps guide companies and institutions on their sustainability journey, as well as collecting and keeping track of sustainability related data. Companies can, therefore, use Laufið to obtain a sustainability-related certification.'
  },
  {
    'title': 'Hvað þýðir hugtakið sjálfbærni?',
    'title_en': 'What does the concept of sustainability mean?',
    'description': 'Í Brundtland skýrslu Sameinuðu þjóðanna sem kom út árið 1987 var sjálfbær þróun skilgreind sem þróun sem mætir þörfum nútímans án þess að skerða möguleika komandi kynslóða til að mæta þörfum sínum. Þetta er algengasta skilgreiningin á sjálfbærri þróun, en hún gengur út á það að við mannfólkið séum ekki að ofnýta auðlindir jarðar á þann hátt að þær geti ekki endurnýjað sig. Jafnvægi og samspil umhverfis og samfélags skiptir því miklu máli hvort sem það snýr að mannréttindum, jafnrétti, umhverfisvernd, sjálfbærri nýtingu og góðum stjórnarháttum. <br/><br/> Í viðskiptalífinu er gjarnan talað um að umhverfi, félagslegir þættir og stjórnarhættir séu þrjár meginstoðir sjálfbærrar þróunar. Fyrirtæki og stofnanir sem vilja huga að sjálfbærri þróun í rekstri þurfa því að hlúa að jafnvægi milli þessara þriggja stoða.',
    'description_en': 'In the United Nations Brundtland report which was published in 1987, sustainable development was defined as development that meets the needs of the present without compromising the ability of future generations to meet their own needs. This is the most common definition of sustainable development, but this definition explains the importance of people not overusing the earth’s resources faster than they can be renewed. The balance and interaction between the environment and societies is therefore of great importance, whether it refers to human rights, equal rights, environmental protection, sustainable sourcing or good governance.  <br/><br/> In business, environmental, social factors, and governance are considered the three main pillars of sustainable development. Companies and organisations that want to incorporate sustainable development into their operations need to maintain a good balance among these three pillars.'
  }
];

export const SoftwareData = [
  {
    'title': 'Hvernig set ég aðgerð í aðgerðaáætlun?',
    'title_en': 'How do I add an action to the action plan?',
    'description': 'Hægt er að setja aðgerð í aðgerðaáætlun með því að smella á hjartað sem er við hverja aðgerð. Þar getur þú svo valið um að setja ákveðinn tímamörk fyrir það hvenær þið viljið vera búin að uppfylla þessa tilteknu aðgerð. Þá er einnig hægt að sleppa því, eða gera það seinna í aðgerðaáætluninni. Þegar búið er að smella á hjartað má finna aðgerðina í aðgerðaáætluninni undir “Markmið”. Þar hafið þið möguleika á að vinna nánar með hverja aðgerð, tengja hana við markmið sem fyrirtækið hefur sett sér eða jafnvel velja ábyrgðaraðila. <br/><br/>Þegar þið hafið svo lokið við að uppfylla aðgerðina þarf að merkja við hana undir “aðgerðir”. Í kjölfarið dettur aðgerðin út úr aðgerðaáætluninni og fer á listann “aðgerðum lokið” sem finna má undir “Markmið”. <br/><br/> Við mælum einnig með að horfa á kennslumyndband þar sem við förum í gegnum akkúrat þetta.',
    'description_en': 'You can add an action to the action plan by clicking the heart located by each action. There you can choose a timeframe for when you want to be done with that particular action. You can also skip that for now and do it later in the action plan. When you have clicked the heart, the action will be visible in the action plan under the “Goals” tab. There you have the ability to work more closely with each action, connect it to a goal the company has set, or even select a person that will be responsible for a particular action. <br/><br/> When you have fulfilled the action you need to check it off under the “Actions” tab. After that, the action disappears from the action plan and goes to the “Actions completed” list, which can be found under “Goals”. <br/><br/> We also recommend watching the tutorial where we show you how to best utilise the action plan.'
  },
  {
    'title': 'Hvernig er hægt að bæta við nýjum notanda?',
    'title_en': 'How can I add a new user?',
    'description': 'Hægt er að bæta við notenda undir “stillingar” í stjórnendaaðgangnum. Það fer þó eftir því í hvaða áskriftarpakka fyrirtækið er í hversu marga notendur er hægt að skrá. Hægt er að skoða áskriftarpakkann einnig undir “stillingar”.',
    'description_en': 'You can add a user under “settings” in admin. However, it depends on which subscription package the company is in, how many users can be registered. The subscription package can also be viewed under “settings”.'
  },
  {
    'title': 'Hvað megum við hafa marga notendur að hugbúnaðinum?',
    'title_en': 'How many users can have access to the software?',
    'description': 'Fjöldi notenda fer eftir áskriftarpakka, en í raun eru engin takmörk sett fyrir því hversu mörg geta haft aðgang. Þau sem ættu að hafa aðgang að hugbúnaðinum eru þau sem vinna með stefnumótun eða sjálfbærnimál innan fyrirtækisins. Það gæti t.d. verið starfsfólk sem sér um mannauðsmál, umhverfismál, sjálfbærnimál, innkaup og fleira.',
    'description_en': 'The number of users depends on the subscription package, but there isn’t any limit on the number of users. Those who should have access to the software are commonly those who with strategy or sustainability issues within the company. It could be employees that manage e.g. human resources, environmental or sustainability issues, procurement and more.'
  },
  {
    'title': 'Ég vil slökkva á sýnileika fyrirtækisins inni á laufid.is, hvernig geri ég það?',
    'title_en': 'I want to turn off the company’s visibility on laufid.is, how can I do that?',
    'description': 'Laufið leggur mikið upp úr gagnsæi í sjálfbærniupplýsingagjöf sem og greitt aðgengi að slíkum upplýsingum. Það gæti þó komið upp sú staða að þið viljið slökkva tímabundið á sýnileikanum. Þá er hægt að fara undir “stillingar” inni í stjórnendaaðgangnum og slökkva eða kveikja á sýnileikanum þar.',
    'description_en': 'Laufið places great emphasis on transparency when it comes to sustainability information, as well as the accessibility to such information. However, there may be a situation where you want to temporarily turn off the visibility. Then you can go to “settings” in the admin system and turn off or on the visibility.'
  },
  {
    'title': 'Er hægt að vinna í hugbúnaðinum án nettengingar?',
    'title_en': 'Can the software be used without a wifi connection?',
    'description': 'Þar sem hugbúnaðurinn er eingöngu aðgengilegur í vafra er, því miður, ekki hægt að vinna í honum án nettengingar.',
    'description_en': ' Unfortunately, since the software is only accessible in a browser, you cannot use it without a wifi connection.'
  },
  {
    'title': 'Afhverju er “á ekki við” hnappur eingöngu við sumar aðgerðir?',
    'title_en': 'Why is the “does not apply” button only for some actions?',
    'description': 'Þar sem fyrirtæki eru jafn misjöfn og þau eru mörg, þá eru einhverjar aðgerðir þannig að þær gætu bara alls ekki átt við öll fyrirtæki eða rekstur. Það eru t.d. ekki öll fyrirtæki sem reka fyrirtækjabíl og því þarf að vera “á ekki við” hnappur við aðgerðina sem spyr hvert hlutfall fyrirtækjabíla sem ganga fyrir rafmagni er. Þá eru einnig sum fyrirtæki sem senda ekki frá sér pakkningar, og því þurfa slík fyrirtæki að geta átt kost á að haka í “á ekki við” við þess tengdar aðgerðir. Við reynum þá að hafa hnappinn eingöngu við viðlíka aðgerðir sem eru alfarið bundnar eðli reksturs fyrirtækja. Ef þið teljið einhverjar aðgerðir ættu að hafa “á ekki við” hnapp, þá er velkomið að senda á okkur línu á laufid@laufid.is og við tökum samtalið.',
    'description_en': 'Given the diversity of companies, certain actions may not be relevant to all companies or business operations. There are e.g. not all companies that operate a company car, so there needs to be a "does not apply" button for the action that asks what percentage of company cars run on electricity. There are also some companies that do not send out packages, and therefore such companies need to be able to tick "does not apply" to the related actions. We then try to have the button only for similar actions that are entirely tied to the nature of business operations. If you have suggestions for actions that should have a "does not apply" option, please reach out to us at laufid@laufid.is and we can start the discussion.'
  },
  {
    'title': 'Get ég deilt árangri fyrirtækisins í Laufinu eða öðrum gögnum inn á eigin miðlum eða til hagaðila?',
    'title_en': 'Can I share the company’s progress in Laufið or other data on our own media or with our stakeholders?',
    'description': 'Svo sannarlega! Ef óskin er að birta árangurinn inná vefsíðu fyrirtækisins er einfaldlega hægt að nýta sér viðmótseininguna (widget) sem er á mælaborðinu.',
    'description_en': 'Indeed! If you want to publish the results on the company\'s website, you can simply use the widget on the dashboard.'
  },
  {
    'title': 'Er hægt að skrá starfsfólk sem ábyrgðaraðila sem ekki hefur aðgengi að hugbúnaðinum?',
    'title_en': 'Is it possible to register employees as responsible parties who do not have access to the software?',
    'description': 'Já, það er hægt :) Til þess að gera það þarf einfaldlega að skrifa nafn viðkomandi við viðeigandi aðgerð í aðgerðaáætluninni sem er aðgengileg undir “markmið”. Það er þá gert með því að smella á blýantinn við aðgerðina.',
    'description_en': 'Yes, it\'s possible :) To do that, you simply have to write the person\'s name next to the appropriate action in the action plan, which is accessible under "goals". That is done by clicking on the pencil next to each action.'
  },
  {
    'title': 'Hvernig skrái ég mig inn?',
    'title_en': 'How do I log in?',
    'description': 'Notendur skrá sig inn með rafrænum skilríkjum. Ef innskráningin er ekki að virka sem skyldi biðjum við ykkur um að hafa samband við laufid@laufid.is.',
    'description_en': 'Users log in by using their electronic ID. If you are having an issue with logging in, please contact is by emailing laufid@laufid.is.'
  },
  {
    'title': 'Virkar hugbúnaðurinn í spjaldtölvu eða snjallsíma?',
    'title_en': 'Does the software work on a tablet or smartphone?',
    'description': 'Hugbúnaðurinn er eingöngu aðgengilegur í vafra, svo hægt er að opna hann í hvaða tæki sem er. Þó er viðmót Laufsins ekki hannað fyrir snjallsíma (enn), svo við mælum með að vinna í honum annað hvort í tölvu eða spjaldtölvu.',
    'description_en': 'The software is only accessible in a browser so it can be accessed on any device. However, Laufið’s interface is not designed for smartphones (yet), so we recommend using either a computer or tablet.'
  }
]

export const SurveysData = [
  {
    'title': 'Þegar við erum að setja okkur SMART markmið hversu langt fram í tímann ættu markmiðin að ná?',
    'title_en': 'When we create SMART goals, how far into the future should the goal reach?',
    'description': 'Samkvæmt European Sustainability Reporting Standard (ESRS) staðlinum, sem er leiðarvísir fyrirtækja til að uppfylla CSRD reglugerðina, ættu fyrirtæki að setja sér markmið fyrir þrennslags tímabil: (1) skammtíma markmið milli ára; (2) markmið sem ná til næstu 2-5 ára, og; (3) langtímamarkmið sem ná lengra en 5 ár fram í tímann.',
    'description_en': 'According to the European Sustainability Reporting Standard (ESRS), a guide for companies who are required to fulfil the Corporate Sustainability Reporting Directive (CSRD), companies should create goals for three different time periods: (1) short-term goals for each year; (2) goals covering the next 2-5 year, and; (3) long-term goals that extend beyond 5 years into the future.'
  },
  {
    'title': 'Þarf fyrirtækið mitt að gefa út árlega sjálfbærniskýrslu?',
    'title_en': 'Is my company required to publish an annual sustainability report?',
    'description': 'Samkvæmt Evrópulögum þurfa í dag eingöngu stór fyrirtæki (sem eru skilgreind sem svo), sem og skráð fyrirtæki á opinberum markaði (fyrir utan míkró fyrirtæki) að birta Sjálfbærniskýrslu.<br><br>Þó mun það taka breytingum á komandi árum, en samkvæmt sjálfbærniskýrslutilskipuninni (CSRD), þurfa skráð lítil- og meðalstór fyrirtæki að gefa út sjálfbærniskýrslu árið 2027 fyrir fjárhagsárið 2026. Gera má svo ráð fyrir að þessi reglugerð muni ná til fleiri fyrirtækja sem ekki eru skráð í nánustu framtíð. <br><br>Samt sem áður eru öll fyrirtæki hvött til að gefa út sjálfbærniskýrslur, en gefnar hafa verið út leiðbeiningar eða staðlar fyrir valfrjálsa sjálfbærniupplýsingagjöf. Það eru þá fjölmargir kostir fólgnir í því að gefa út sjálfbærniskýrslur þar sem slíkt gefur að kynna að fyrirtækið taki sjálfbærnimálin föstum tökum, sem skilar sér á endanum í auknu trausti hagsmunaaðila, skýrari stefnumótun og skilvirkari rekstrarferlum.',
    'description_en': 'As for now, according to European law, only large companies (which are defined as such), as well as registered companies on the public market (except for micro-companies) have to publish a Sustainability Report.<br><br>Although this will change in the coming years, but according to the Corporate Sustainability Reporting Directive (CSRD), registered SMEs will have to issue a sustainability report in 2027 for the financial year 2026. It can be assumed that this regulation will cover more non-registered companies in the near future. <br><br>However, all companies are encouraged to publish sustainability reports, but guidelines or standards for optional sustainability disclosures have been issued. There are many advantages to issuing sustainability reports, as this indicates that the company takes sustainability issues seriously, which ultimately results in increased stakeholder trust, clearer strategic planning and more efficient operational processes.'
  },
  {
    'title': 'Hvað er grænþvottur og hvernig er hægt að koma í veg fyrir grænþvott?',
    'title_en': 'What is greenwashing and how can it be prevented?',
    'description': 'Grænþvottur er hugtak sem lýsir því þegar fyrirtæki setur fram rangar eða villandi upplýsingar um umhverfiságæti vöru eða starfsemi. Að auki getur grænþvottur átt sér stað þegar fyrirtæki reynir að leggja áherslu á sjálfbæra þætti vöru eða til þess að skyggja á þátttöku fyrirtækisins í óumhverfisvænum starfsháttum.<br><br>Í einhverjum tilfellum getur grænþvottur átt sér stað án vitundar starfsfólks, oft vegna vanþekkingar. Til þess að koma í veg fyrir grænþvott er mikilvægt að kynna sér vel mögulega kosti og galla vöru eða fyrirtækisins sjálfs. Þá þarf að passa sig að nota ekki gildishlaðnar yfirlýsingar sem hafa ekki viðeigandi vottanir eða sannanir. Slíkar yfirlýsingar eru t.d. “náttúruleg”, “niðurbrjótanleg” og “græn”. <br><br>Fyrirtæki sem verða uppvís um grænþvott geta átt von á sektum samkvæmt lögum nr. 57/2005 um eftirlit með viðskiptaháttum og markaðssetningu.',
    'description_en': 'Greenwashing is a term that describes when a company presents false or misleading information about the environmental quality of a product or activity. In addition, greenwashing can occur when a company tries to emphasise the sustainable aspects of a product or to obscure the company\'s involvement in environmentally unfriendly practices.<br/><br/>In some cases, greenwashing can take place without knowledge, often due to ignorance. To prevent greenwashing, it is important to familiarise yourself with the possible advantages and disadvantages of the product or the company itself. Care must be taken not to use value-laden statements that do not have appropriate certifications or proofs. Such statements are e.g. "natural", "degradable" and "green".<br/><br/>Companies found guilty of greenwashing can expect fines according to Act no. 57/2005 on control of business practices and marketing.'
  },
  {
    'title': 'Fyrirtækið er að hefja sína sjálfbærnivegferð svo við erum ekki með mælingar til staðar til þess að geta sett okkur SMART markmið, hvað getum við gert?',
    'title_en': 'The company is starting its sustainability journey so we don\'t have metrics in place to set SMART goals, what can we do?',
    'description': 'Það er rétt að það þurfa að liggja fyrir mælingar til þess að geta sett sér SMART markmið. Einhverjar af þeim upplýsingum sem þið þurfið liggja fyrir í bókhaldsgögnum, t.d. notkun á jarðefnaeldsneyti á ökutæki, magn úrgangs sem fellur til hjá fyrirtækinu, notkun á heitu vatni og rafmagni, kynjahlutfall starfsfólks, launamunur kynja, og fleira. <br><br>Gott er að byrja á því að setja sér raunhæf markmið sem þið vitið með nokkurri vissu að þið getið uppfyllt, en þetta getur farið mikið eftir eðli ykkar starfsemi. Þá gæti einnig verið nytsamlegt að skoða hvernig markmið aðilar í sambærilegum rekstri eru að setja sér og miða út frá því. Ef gögnin, talin upp að ofan,  liggja ekki fyrir getur fyrsta markmið ykkar verið að byrja á að safna viðeigandi gögnum til þess að geta mótað SMART markmið á næsta ári. Hugbúnaður Laufsins er þá góður vettvangur til að halda gögnum til haga ár frá ári og sjá þróunina með lýsandi hætti.',
    'description_en': 'It is true that you need metrics in order to set SMART goals. Some of the information you need is available in the accounting records, e.g. use of fossil fuel per vehicle, amount of waste generated by the company, use of hot water and electricity, gender ratio of employees, gender pay gap, and more.<br><br>It\'s a good idea to start by setting realistic goals that you know with some certainty that you can achieve, but this can depend a lot on the nature of your business. It can also be helpful to examine how similar businesses are setting their goals and use that information as a reference point. If the data listed above is not available, your first goal may be to start collecting relevant data in order to formulate SMART goals for the next year. Laufið’s software is then a good platform to keep data available year after year and to see the development visually.'
  },
  {
    'title': 'Afhverju ættum við að setja okkur SMART markmið?',
    'title_en': 'Why should we create SMART goals?',
    'description': 'SMART markmið, eða skýr, mælanleg, aðgerðamiðuð, raunhæf og tímasett markmið, hjálpa fyrirtækjum að setja sér nákvæmari ramma varðandi stefnu þess í sjálfbærnimálum og koma í veg fyrir óþarfa sóun á verðmætum auðlindum.',
    'description_en': 'SMART goals, or clear, measurable, action-oriented, realistic and timed goals, help companies set a more precise framework for their sustainability strategy and prevent unnecessary wastage of valuable resources.'
  },

  {
    'title': 'Hvað er kolefnisspor?',
    'title_en': ' What is a carbon footprint?',
    'description': 'Kolefnisspor er mælikvarði á losun gróðurhúsalofttegunda en hægt er að reikna þessa losun fyrir t.d. fyrirtæki og vörur. Þegar losun gróðurhúsalofttegunda frá fyrirtækjum er mæld er almennt notast við alþjóðlega staðalinn Greenhouse Gas Protocol, eða GHG Protocol. <br><br>Þó svo losun sé ólík eftir eðli fyrirtækja þá eru ákveðnir þættir sem eiga við um allan rekstur; úrgangur, orkunotkun og samgöngur. Staðallinn skiptir losun upp í þrennt eftir því hvar hún á sér stað, hvort hún sé bein eða óbein, og kallast umfang 1, 2 og 3.',
    'description_en': 'Carbon footprint is a measure of greenhouse gas emissions, but this emission can be calculated for e.g. companies and products. When greenhouse gas emissions from companies are measured, the international standard Greenhouse Gas Protocol, or GHG Protocol, is generally used.<br><br>Although emissions differ depending on the nature of companies, there are certain factors that apply to all operations; waste, energy consumption and transport. The standard divides emissions into three levels depending on where they occur, whether they are direct or indirect, and are called scope 1, 2 and 3.'
  },
  {
    'title': 'Hvað þýðir kolefnisígildi?',
    'title_en': 'What does carbon equivalent mean?',
    'description': 'Þegar kolefnisspor fyrirtækis er reiknað er ekki eingöngu horft til losunar koltvísýrings (CO2), heldur einnig metans (CH4), brennisteinshexaflúoríðs (SF6), hláturgass (N2O), perflúorkolefnis (PFC) og vetnisflúorkolefnis (HFC), sem eru algengar lofttegundir sem fyrirtæki losa. <br><br>Til þess að geta borið saman losun frá mismunandi starfsemi er losun þessara lofttegunda reiknuð í koltvísýringsígildum (CO2-íg) sem saman mynda kolefnisspor.',
    'description_en': 'When calculating a company\'s carbon footprint, carbon dioxide (CO2) is not the only emissions that are considered, but also methane (CH4), sulfur hexafluoride (SF6), laughing gas (N2O), perfluorocarbons (PFCs) and hydrofluorocarbons (HFCs), which are common gases emitted by companies.<br><br>In order to compare emissions from different activities, the emissions of these gases are calculated in carbon dioxide equivalents (CO2), which together form a carbon footprint.'
  },
  {
    'title': ' Hvernig virkar hringrásarhagkerfið?',
    'title_en': 'How does the circular economy work?',
    'description': 'Hringrásarhagkerfi er mikilvæg umbreyting sem þarf að hafa í huga að til þess að tryggja sjálfbærari framtíð. En hvernig virkar hringrásarhagkerfi? Jú, það virkar þannig hringrás vöru og hráefna fer í hring með ferlum þar sem þeim er ýmist viðhaldið, þær endurnýttar, endurbættar, endurframleiddar, endurunnar eða jarðgerðar. Línulegt hagkerfi endar þá yfirleitt með urðun þar sem varan eða hráefnið er fyrir einhver tilstilli ekki hægt að nýta áfram.<br><br>Það getur verið krefjandi fyrir fyrirtæki að umbreyta línulegu viðskiptamódeli yfir í hringrásar viðskiptamódel. Oft þarf að hafa til staðar ríka nýsköpunarhugsun og sköpun að vopni, en fyrsta skrefið er að greina virðiskeðjuna vel og þá virkar að vera með sömu spurningar í huga eins og: “Úr hverju er varan?” “Hvar er hún framleidd?” “Hversu lengi endist hún?” “Hvar endar hún?” Í einhverjum tilfellum þarf einfaldlega að endurhugsa viðskiptamódel fyrirtækisins frá grunni þar sem línulega módelinu er fasað út með tíma og hringrásarmódel tekur við. Í öðrum tilfellum gæti nægt að endurhanna eða endurhugsa vöruna eða framleiðsluferla.',
    'description_en': 'A circular economy is a crucial shift that must be considered to ensure a more sustainable future. But how does a circular economy function? It involves a cycle where products and raw materials are either maintained, reused, improved, remanufactured, recycled, or composted, as opposed to a linear economy where products and raw materials often end up in landfills after use.<br><br>Transforming a linear business model into a circular one can be challenging for companies. It requires a strong innovative mindset and creativity. The first step involves thoroughly analysing the value chain and asking questions such as: "What is the product made of?" "Where is it made?" "How long will it last?" "Where does it end?" In some cases, the entire business model may need to be rethought, while in other cases, redesigning or rethinking the product or production processes may be sufficient.'
  },
  {
    'title': 'Hvernig virkar kolefnisbinding?',
    'title_en': 'How does carbon sequestration work?',
    'description': 'Í einföldum orðum virkar kolefnisbinding þannig að kolefni er bundið ýmist í gróðri, jarðvegi, bergi eða á annan hátt í gegnum verkefni eða tæknilausnir sem koma í veg fyrir losun sem annars hefði átt sér stað. Fyrirtæki fjárfesta þá í slíkum verkefnum með kaupum á kolefniseiningum sem er ákveðin mótvægisaðgerð á móti losun fyrirtækisins.<br><br>Hafa skal þó í huga að fjárfesting í kolefniseiningum þarf að eiga sér stað samhliða vinnu við að draga úr losun. Kolefnisbinding kemur ekki í stað samdráttar á losun gróðurhúsalofttegunda.',
    'description_en': 'In simple terms, carbon sequestration works by sequestering carbon either in vegetation, soil, rock or in other ways through projects or technical solutions that prevent emissions that would otherwise have occurred. Companies then invest in such projects with the purchase of carbon units, which is a certain countermeasure against the company\'s emissions.<br><br>However, it should be kept in mind that investment in carbon units must take place in parallel with work to reduce emissions. Carbon sequestration does not replace reductions in greenhouse gas emissions.'
  }
]

