import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import {Card, CardBody, CardHeader} from "reactstrap"
import {LoaderSpinner} from "../../components/Common/Loader";
import AddCompany from "./addCompany";
import CompanyTable from "./companyTable";
import {
  getCompanies,
  companyCreateIn1819,
  disableEnableCompany,
  createUpdateCompany
} from "../../store/actions/company";
import {helperFormatDate} from "../../helpers/main";
import { saveAs } from 'file-saver';
import { utils,  write } from 'xlsx';
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {companies, loading} = useSelector( state => state.company );
  const [companiesData, setCompaniesData] = useState([]);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if(companies.results) {
      let data = [...companies.results.companies];
      companies.results.companies.forEach((item,index) => {
        data[index].company_categories = item.company_category_nxns ? item.company_category_nxns.map(data => data.company_category_id) : []
      });
      setCompaniesData(data)
    }
  },[companies.results]);

  const confirmConnected = (status, data) => {
    Swal({
      title: status === true ? t('company_page.disconnect_1819') : t('company_page.connect_1819'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        let saveData = {
          "id": data.id,
          "visible": !status
        };
        dispatch(createUpdateCompany(true, saveData, 'companies'));
      } else {
        Swal.close();
      }
    });
  };

  const confirmCreateCompany = (kennitala) => {
    Swal({
      title: t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(companyCreateIn1819(kennitala));
      } else {
        Swal.close();
      }
    });
  };

  const confirmDisableEnableCompany = (type, data) => {
    Swal({
      title: type === 'disable' ? 'Þessi aðgerð óvirkjar aðgang viðkomandi fyrirtækis. Ertu viss um að þú viljir óvirkja?' : t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(disableEnableCompany(type, data));
      } else {
        Swal.close();
      }
    });
  };

  const handleCreateUpdateCompany = (data, update) => {
    dispatch(createUpdateCompany(update, data, 'companies'));
  }

  const editCompany = (data = {}) => {
    setAlert(
      <AddCompany data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => handleCreateUpdateCompany(data, update)} />
    );
  };

  const onClickExportData = () => {
      let excelCompaniesData = [];
      let excelCompaniesGreenStepData = [];
      companies.results.companies.forEach((company,index) => {
        let excelGreenStepData = [];
        excelCompaniesData.push({
          'Nafn': company.name,
          'Kennitala': company.kennitala,
          'Plan': company.plan ? company.plan.name : '',
          'Sýnilegt': company.visible,
          'Stofnað': company.created_at ? helperFormatDate(new Date(company.created_at)) : '',
          'Aðgerðir-Umhverfi': company.green_step_category_count ? company.green_step_category_count['Umhverfi']+'%' : '',
          'Aðgerðir-Félagslegir-þættir': company.green_step_category_count ? company.green_step_category_count['Félagslegir þættir']+'%' : '',
          'Aðgerðir-Stjórnarhættir': company.green_step_category_count ? company.green_step_category_count['Stjórnarhættir']+'%' : '',
          'Detailed information in': `Sheet-${index+1}`,
        })

        companies.results.green_steps.forEach((greenStep,index) => {
          let bigCategories = [];
          let smallCategories = [];

          greenStep.children_category.forEach((category,index) => {
            if(category.icon !== null) {
              category.new_green_steps.forEach((item,index) => {
                bigCategories.push(
                  {
                    'Main Category': greenStep.name,
                    'Sub Category': category.name,
                    'Type': 'Stór skref',
                    'Green Step': item.title,
                    'Score': item.score,
                    'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
                  }
                )
              });
              category.categories.forEach((subCategory,index) => {
                subCategory.new_green_steps.forEach((item,index) => {
                  bigCategories.push({
                    'Main Category': greenStep.name,
                    'Sub Category': category.name,
                    'Type': 'Stór skref',
                    'Green Step': item.title,
                    'Score': item.score,
                    'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
                  })
                });
              });
              bigCategories.push({
                'Main Category': '',
                'Sub Category': '',
                'Type': '',
                'Green Step': '',
                'Score': '',
                'Finished': '',
              })
            } else {
              category.new_green_steps.forEach((item,index) => {
                smallCategories.push(
                  {
                    'Main Category': greenStep.name,
                    'Sub Category': category.name,
                    'Type': 'Smá skref',
                    'Green Step': item.title,
                    'Score': item.score,
                    'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
                  }
                )
              });
              category.categories.forEach((subCategory,index) => {
                subCategory.new_green_steps.forEach((item,index) => {
                  smallCategories.push({
                    'Main Category': greenStep.name,
                    'Sub Category': category.name,
                    'Type': 'Smá skref',
                    'Green Step': item.title,
                    'Score': item.score,
                    'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
                  })
                });
              });
              smallCategories.push({
                'Main Category': '',
                'Sub Category': '',
                'Type': '',
                'Green Step': '',
                'Score': '',
                'Finished': '',
              })
            }
          });

          excelGreenStepData.push(...bigCategories)
          excelGreenStepData.push(...smallCategories)
        });

        excelCompaniesGreenStepData.push({
          "company": company.name,
          "data": excelGreenStepData
        })
      });

      if(companies.results.companies.length === excelCompaniesGreenStepData.length) {
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, utils.json_to_sheet(excelCompaniesData), 'Companies');
        excelCompaniesGreenStepData.forEach((item, index) => {
          utils.book_append_sheet(workbook, utils.json_to_sheet(item.data), `Sheet-${index+1}`);
        });
        const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `Companies Data.xlsx`);
      }

  }

  const onClickExportSingleData = (company) => {
    let excelGreenStepData = [];

    companies.results.green_steps.forEach((greenStep,index) => {
      let bigCategories = [];
      let smallCategories = [];

      greenStep.children_category.forEach((category,index) => {
        if(category.icon !== null) {
          category.new_green_steps.forEach((item,index) => {
            bigCategories.push(
              {
                'Main Category': greenStep.name,
                'Sub Category': category.name,
                'Type': 'Stór skref',
                'Green Step': item.title,
                'Score': item.score,
                'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
              }
            )
          });
          category.categories.forEach((subCategory,index) => {
            subCategory.new_green_steps.forEach((item,index) => {
              bigCategories.push({
                'Main Category': greenStep.name,
                'Sub Category': category.name,
                'Type': 'Stór skref',
                'Green Step': item.title,
                'Score': item.score,
                'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
              })
            });
          });
          bigCategories.push({
            'Main Category': '',
            'Sub Category': '',
            'Type': '',
            'Green Step': '',
            'Score': '',
            'Finished': '',
          })
        } else {
          category.new_green_steps.forEach((item,index) => {
            smallCategories.push(
              {
                'Main Category': greenStep.name,
                'Sub Category': category.name,
                'Type': 'Smá skref',
                'Green Step': item.title,
                'Score': item.score,
                'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
              }
            )
          });
          category.categories.forEach((subCategory,index) => {
            subCategory.new_green_steps.forEach((item,index) => {
              smallCategories.push({
                'Main Category': greenStep.name,
                'Sub Category': category.name,
                'Type': 'Smá skref',
                'Green Step': item.title,
                'Score': item.score,
                'Finished': item.new_company_green_steps.findIndex( f=> f.company_id === company.id) >= 0 ? 'Já' : 'Nei',
              })
            });
          });
          smallCategories.push({
            'Main Category': '',
            'Sub Category': '',
            'Type': '',
            'Green Step': '',
            'Score': '',
            'Finished': '',
          })
        }
      });

      excelGreenStepData.push(...bigCategories)
      excelGreenStepData.push(...smallCategories)
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, utils.json_to_sheet(excelGreenStepData), 'Data');
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${company.name}-data.xlsx`);

  }

  if (loading) return (<LoaderSpinner/>);

  return (
    <div className="page-content admin_companies_page">
      {alert}
      <Card>
        <CardHeader>
          <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onClickExportData()}>
            Export Companies
          </button>
          <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => editCompany()}>
            Add new Company
          </button>

        </CardHeader>
        <CardBody>
          <CompanyTable
            data={companiesData}
            leafsData={[]}
            confirmConnected={confirmConnected}
            confirmCreateCompany={confirmCreateCompany}
            confirmDisableEnableCompany={confirmDisableEnableCompany}
            editCompany={editCompany}
            exprtCompany={onClickExportSingleData}
          />
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
