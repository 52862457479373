import React, {useEffect, useState} from "react"
import {Check} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import Switch from "react-switch";
import {Input} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createUpdateCompany} from "../../store/actions/company";
import TextareaAutosize from "react-textarea-autosize";
import {customDropdownSmallStyles, customDropdownWithLogo} from "../../helpers/dropdown";
import Select from "react-select";
import {LoaderSpinner} from "../../components/Common/Loader";

const companySizeOptions = [
  {label: 'Micro <10', value: '1'}, {label: 'Lítil 10-50', value: '2'},
  {label: 'Mið 50-250', value: '3'}, {label: 'Stór 250+', value: '4'}
];

const Companies = () => {
  const dispatch = useDispatch();
  const {updatedStore} = useSelector(state => state.company);
  const {categories, loadingCategories} = useSelector( state => state.companyCategory);
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [firstCompany, setFirstCompany] = useState(userData && userData.companies.length>0 ? userData.companies[0] : false);
  const [state, setState] = useState({"id": 0});
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    if(updatedStore) {
      setFirstCompany(userData.companies[0]);
    }
  }, [updatedStore]);

  useEffect(() => {
    if(categories && categories.results) {
      let subCategoriesTemp = [];
      categories.results.forEach(item => {
        subCategoriesTemp.push(...item.sub_categories)
      })
      setAllSubCategories(subCategoriesTemp);
    }
  }, [categories]);

  const editData = (stateName, data, id) => {
    setState({
      id: id,
      [stateName]: data || ' '
    })
  };

  const editCategory = (categoryId, id) => {
    let category = allSubCategories.find(f => f.id === firstCompany.company_category_id);
    setState({
      id: id,
      company_category_id: categoryId || 0,
      categoryOption: category ? {label: category.title, value: category.id, logo: category.logo} : {}
    });
  };

  const change = (event, stateName) => {
    let data = event.target.value;
    let valLen = data.length;
    if(valLen === 2) {
     data.replace(/^\s+/,"");
    }
    if(valLen === 0) {
     data = ' '
    }
    setState({...state, [stateName]: data});
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  const saveData = (stateName) => {
    let data = {...state};
    if(
      data[stateName].length > 3 ||
      (stateName === 'email' && verifyEmail(state.email)) ||
      (stateName === 'company_size' && data[stateName]>0) ||
      (stateName === 'company_category_id' && data['company_category_id']>0)
    ) {
      setState({id: 0});
      dispatch(createUpdateCompany(true, data));
    } else {
      setErrorStatus(true);
    }
  };

  const toggleCompanyVisible = (company) => {
    let data = {
      "id": company.id,
      "visible": !company.visible
    };
    dispatch(createUpdateCompany(true, data));
  };

  if (loadingCategories) return (<LoaderSpinner/>);

  return (
    <div className="row companies">
      <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
        <div className="card">

          <div className="card-title">
            <div className="title">{t('settings_page.company_profile')}</div>
            <div className="addNew">
              <Switch
                id={'toggleCompanyActive'}
                checked={firstCompany.visible}
                onChange={() => toggleCompanyVisible(firstCompany)}
                activeBoxShadow="0px 0px 1px 2px #f3f3f3"
                className={`react-custom-switch ${firstCompany.visible === true ? 'active' : ''}`}
                checkedHandleIcon={
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <Check/>
                  </div>
                }
              />
              {t('settings_page.make_public')}
            </div>
          </div>

          <div className="card-body">
            <div className="table">
              <div className="tableBody">

                {firstCompany && <React.Fragment>
                  <div className="row">
                    <div className="col name">{t('settings_page.name')}</div>
                    <div className="col name">
                      { state.id > 0 && state.name ?
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.name}
                          className="form-control"
                          onChange={event => change(event, 'name')}
                          invalid={errorStatus && state.name.length<3}
                        /> :
                        firstCompany.name || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.name ?
                        <div className="action edit hover-effect" onClick={() => saveData('name')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('name', firstCompany.name, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.category')}</div>
                    <div className="col category">
                      { state.id > 0 && state.company_category_id >=0 ?
                        <Select
                          isSearchable={false}
                          placeholder={t('user_page.role')}
                          styles={customDropdownWithLogo}
                          value={state.categoryOption || {}}
                          onChange={(event) =>
                            setState({
                              id: state.id,
                              company_category_id: event.value,
                              categoryOption: event
                            })
                          }
                          options={categories.results ? categories.results.map(item =>
                            {
                              return {
                                label: item.title, logo: item.logo, options: item.sub_categories.map(cat => {
                                  return {label: cat.title, value: cat.id, logo: cat.logo}
                                })
                              }
                            })
                            : []}
                          formatOptionLabel={item => Object.keys(item).length>0 ?  (
                            <div className="categoriesOption">
                              <img src={item.logo} alt="category-image"/>
                              <span>{item.label}</span>
                            </div>
                          ) : null}
                        /> :
                        firstCompany.company_category_id && allSubCategories.length>0 ? allSubCategories.find(f => f.id === firstCompany.company_category_id)?.title : ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.company_category_id ?
                        <div className="action edit hover-effect" onClick={() => saveData('company_category_id')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editCategory(firstCompany.company_category_id, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.website')}</div>
                    <div className="col website">
                      { state.id > 0 && state.website ?
                        <Input
                          id="website"
                          name="website"
                          type="text"
                          value={state.website}
                          className="form-control"
                          onChange={event => change(event, 'website')}
                          invalid={errorStatus && state.website.length<3}
                        /> :
                        firstCompany.website || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.website ?
                        <div className="action edit hover-effect" onClick={() => saveData('website')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('website', firstCompany.website, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('onboarding_page.company_size')}</div>
                    <div className="col company_size">
                      { state.id > 0 && state.company_size ?
                        <Select
                          isSearchable={false}
                          styles={customDropdownSmallStyles}
                          value={state.company_size ? companySizeOptions.find(f => f.value == state.company_size) : {}}
                          onChange={event => setState({...state, company_size: event.value})}
                          options={companySizeOptions}
                          className={`react-select`}
                        />
                       :
                        firstCompany.company_size ? companySizeOptions.find(f => f.value == firstCompany.company_size).label : ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.company_size ?
                        <div className="action edit hover-effect" onClick={() => saveData('company_size')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('company_size', firstCompany.company_size, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.contact_person')}</div>
                    <div className="col contact_name">
                      { state.id > 0 && state.contact_name ?
                        <Input
                          id="contact_name"
                          name="contact_name"
                          type="text"
                          value={state.contact_name}
                          className="form-control"
                          onChange={event => change(event, 'contact_name')}
                          invalid={errorStatus && state.contact_name.length<3}
                        /> :
                        firstCompany.contact_name || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.contact_name ?
                        <div className="action edit hover-effect" onClick={() => saveData('contact_name')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('contact_name', firstCompany.contact_name, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.contact_email')}</div>
                    <div className="col contact_email">
                      { state.id > 0 && state.contact_email ?
                        <Input
                          id="contact_email"
                          name="contact_email"
                          type="text"
                          value={state.contact_email}
                          className="form-control"
                          onChange={event => change(event, 'contact_email')}
                          invalid={errorStatus && state.contact_email.length<3}
                        /> :
                        firstCompany.contact_email || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.contact_email ?
                        <div className="action edit hover-effect" onClick={() => saveData('contact_email')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('contact_email', firstCompany.contact_email, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.year_founded')}</div>
                    <div className="col founded">
                      { state.id > 0 && state.founded ?
                        <Input
                          id="founded"
                          name="founded"
                          type="text"
                          value={state.founded}
                          className="form-control"
                          onChange={event => change(event, 'founded')}
                          invalid={errorStatus && state.founded.length<3}
                        /> :
                        firstCompany.founded || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.founded ?
                        <div className="action edit hover-effect" onClick={() => saveData('founded')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('founded', firstCompany.founded, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col name">{t('settings_page.about_us')}</div>
                    <div className="col description">
                      { state.id > 0 && state.description ?
                        <TextareaAutosize
                          onChange={event => change(event, 'description')}
                          value={state.description}
                          minRows={3}
                          maxRows={8}
                          invalid={errorStatus && state.description.length<3}
                        />
                        :
                        firstCompany.description || ''
                      }
                    </div>
                    <div className="col actions">
                      {state.id > 0 && state.description ?
                        <div className="action edit hover-effect" onClick={() => saveData('description')}>{t('save')}</div>
                        :
                        <div className="action edit" onClick={() => editData('description', firstCompany.description, firstCompany.id)}>{t('edit')}</div>
                      }
                    </div>
                  </div>

                  {/*<div className="row">*/}
                  {/*  <div className="col name">Leitarorð</div>*/}
                  {/*  <div className="col email">{'--'}</div>*/}
                  {/*  <div className="col actions">*/}
                  {/*    <div className="action edit">{t('edit')}</div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*<div className="row">*/}
                  {/*  <div className="col name">Leitarorð - enska</div>*/}
                  {/*  <div className="col email">{'--'}</div>*/}
                  {/*  <div className="col actions">*/}
                  {/*    <div className="action edit">{t('edit')}</div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </React.Fragment>}

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
};
export default Companies;